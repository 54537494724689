import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-request',
  templateUrl: './invalid-request.component.html',
  styleUrls: ['../confirmation/confirmation.component.css']
})

export class InvalidRequestComponent implements OnInit  {

  constructor() { }

  ngOnInit() {
    const failMessage = {
      onFail: {
        data: {
          errors: {
              message: "Authentication failed, request has been tampered with.",          
          }
        }
      }
    }
    console.log("invalid-request.component.ts failMessage being fired correctly...", {failMessage})
    const text = JSON.stringify(failMessage);
    window.parent.postMessage(text, "*");
  }
}
