import {OnInit, ElementRef, Input, Directive} from '@angular/core';

@Directive({selector: '[focusMe]'})
export class FocusDirective implements OnInit {

  @Input('focusMe') isFocused: boolean;

  constructor(private hostElement: ElementRef) {
  }

  ngOnInit() {
    if (this.isFocused) {
      setTimeout(() => this.hostElement.nativeElement.focus(), 0);
    }
  }

  ngOnChanges(){
    if (this.isFocused) {
      setTimeout(() => this.hostElement.nativeElement.focus(), 0);
    }
  }
}
