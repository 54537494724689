import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DefaultComponent} from "./payment/default.component";
import {PaymentItem} from "./payment/payment-item";
import {PaymentInfo} from "./payment/payment-info";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'IPSI';
  paymentItem: PaymentItem;

  constructor() {
  }

  ngOnInit() {
    //this.paymentItem = new PaymentItem(DefaultComponent, new PaymentInfo, null);
  }
}
