import {Component, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {v4 as uuid} from 'uuid';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './newconfirmation.component.html',
  styleUrls: ['./newconfirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  txnStatus: boolean;
  responseMessage: string;
  tokenValue: string;
  merchReference: string;
  cardHolderName: string;
  cardNumber: string;
  amount;
  userName: string;
  captureId: string;
  txnReference: string;
  metadata: Object;
  tokenControl: Object;
  alias: string;

  constructor(public dataservice: DataService, private router: Router, public service: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    let currencyFormat = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    });

    this.txnStatus = this.dataservice.txnStatus;
    this.responseMessage = this.dataservice.responseMessage;
    this.tokenValue = this.dataservice.tokenValue;
    this.merchReference = this.dataservice.merchReference;
    this.cardHolderName = this.dataservice.cardHolderName;
    this.cardNumber = this.dataservice.cardNumber;
    this.amount = currencyFormat.format(parseFloat(this.dataservice.amount));
    this.userName = this.dataservice.userName;
    this.captureId = this.dataservice.captureId;
    this.txnReference = this.dataservice.txnReference;
    this.metadata = this.dataservice.metadata;
    this.tokenControl = this.dataservice.tokenControl;
    this.alias = this.dataservice.alias;
    

    if (!this.txnStatus) {
      const failMessage = {
        onFail: {
          data: {
            errors: {
                merchReference: this.merchReference,
                message: this.responseMessage,
                user: this.userName
            }
          }
        }
      }
      console.log("confirmation.component.ts failMessage being fired correctly...", {failMessage})
      const text = JSON.stringify(failMessage);
      window.parent.postMessage(text, "*");
    }
  }

  // Method to initiate Tokenisation API.
  tokenisation() {
    console.log('Initiating tokenisation from final payment');
    console.log('tokenControl :' + JSON.stringify(this.tokenControl));
    let tokenisationResponse = this.service.tokenise(this.metadata, this.tokenControl, this.alias, this.cardHolderName );
    console.log('Response for tokenisation in confirmation component :' + JSON.stringify(tokenisationResponse));

    tokenisationResponse.then(
      res => {
        this.tokenValue = res['cardToken'];
        if (this.tokenValue != undefined) { // Success
          this.txnStatus = true;
          this.router.navigate(['/confirmation']);
        } else { // Failure
          this.txnStatus = false;
          this.responseMessage = (this.responseMessage == undefined) ? res['errors'] : res['responseMessage'];
          if (this.responseMessage == undefined) {
            this.responseMessage = "Internal system error, please try again later";
          }
          this.router.navigate(['/confirmation']);
        }
      },
      err => {
        this.txnStatus = false;
        this.router.navigate(['/confirmation']);
      }
    );
  }
}
