import { Type } from '@angular/core';
import {PaymentInfo} from "./payment-info";
import {FormGroup} from "@angular/forms";
import {ApiService} from "../services/api.service";
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";

export class PaymentItem {
  constructor(public component: Type<any>, public data: PaymentInfo, public paymentForm: FormGroup, public service: ApiService, public amount: any) {
    data.pan = this.paymentForm.get('panValue');
    data.expiry = this.paymentForm.get('expiryDateValue');
    data.cvv = this.paymentForm.get('secureCodeValue');
    data.amt = this.paymentForm.get('amount');
    data.cardTypeValue = this.paymentForm.get('cardTypeValue').value;
  }

}
