import {AbstractControl, ValidatorFn} from "@angular/forms";

export function panValidator(isPanValid: boolean): ValidatorFn {
  return (control: AbstractControl) => {
    if(control.value !== undefined && !isPanValid){
      return {'isPanValid': isPanValid};
    }
    return null;
  };
}

export function expiryValidator(isExpiryValid: boolean): ValidatorFn {
  return (control: AbstractControl) => {
    if(control.value !== undefined && !isExpiryValid){
      return {'isExpiryValid': true};
    }
    return null;
  };
}

export function cvvValidator(isCvvValid: boolean): ValidatorFn {
  return (control: AbstractControl) => {
    if(control.value !== undefined && !isCvvValid){
      return {'isCvvValid': true};
    }
    return null;
  };
}
