import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Location} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {cvvValidator, expiryValidator, panValidator} from "../validators/validator";
import {environment} from "../../environments/environment";
import {PaymentDirective} from "./payment.directive";
import {PaymentItem} from "./payment-item";
import {PaymentInfo} from "./payment-info";
import {DefaultComponent} from "./default.component";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  configIdMap = environment.configIds;
  panValue;
  isPanValid: boolean = true;
  expiryDateValue;
  expiryDateMask: (string | RegExp)[] = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  isExpiryValid: boolean = true;
  secureCodeValue;
  isCvvValid: boolean = true;
  showCaptureResponse;
  responseMessage;
  txnStatus;
  tokenValue;
  correlationId: string;
  configId: string;
  userName: string;
  txnType: string;
  merchReference: string;
  txnReference: string;
  amount;
  verifyMessage: string;
  serverToken: string;
  cardHolderName;
  params: string;
  tokenFlag: string;
  captureId: string;
  txnTypeText: string;
  onlyTokenise: string;
  cursor: string;
  txnTypeValue;
  isFocusOnName: boolean = false;
  isFocusOnPan: boolean = false;
  isFocusOnExpiry: boolean = false;
  isFocusOnCvv: boolean = false;
  metadata = {};
  tokenControl = {};
  metadataQueryStr: string;
  tokenControlQueryStr: string;
  alias: string;
  disableAmexOnConfigIds = environment.disableAmexOnConfigIds;
  disableDinersOnConfigIds = environment.disableDinersOnConfigIds;
  isAmexAllowed: boolean = true;
  isDinersAllowed: boolean = true;
  isTokeniseAllowed: boolean = false;
  isCardholderNameMandatory: boolean = false;
  postMessageValidators = {
    "cardHolderName":{"validator":/^\w[\w'-\s]+$/, "control": (s) => { this.cardHolderNameCtrl.setValue(s) }}
  }; 


/*NOTE***Card RegExps matching the filter, any change here should be reflected in agentsecure and vice versa***/
visa_valid_format = /^4[0-9]{12}(?:[0-9]{3})?$/;  // 13-16 digits, starting with 4
mc_valid_format = /^5[1-6]\d{14}$/; //# 16 digits, starting 51-55
amex_valid_format = /^3[47]\d{13}$/;  //# 15 digits, starting 34 or 37
diners_valid_format = /^(3([0689])\d{12})|(6[045]\d{14})|(35[2-8]\d{13})$/;
// # diners, 14 digits starting with 30 or 36 or
// # 16 digits, starting with 38,39,60,64 or 65
// # 16 digits, starting with 3528 - 3589

/* Open versions of the format regex for progressive detection */
visa_detect_format = /^4[0-9]{1,12}(?:[0-9]{3})?/;
mc_detect_format = /^5[1-6]\d{1,14}/;
amex_detect_format = /^3[47]\d{1,13}/;
diners_detect_format = /^(3([0689])\d{2,12})|(6[045]\d{0,14})|(35[2-8]\d{0,13})/;

  paymentForm: FormGroup;
  cardHolderNameCtrl: FormControl;
  panValueCtrl: FormControl;
  expiryDateCtrl: FormControl;
  cvvCtrl: FormControl;
  amountCtrl: FormControl;
  cardTypeValue = 'all';
  txnTypeCtrl: FormControl;

  @Input() paymentItem: PaymentItem;
  @ViewChild(PaymentDirective, {static: true}) paymentHost: PaymentDirective;
  @Input() data: PaymentInfo;

  constructor(private route: ActivatedRoute, private router: Router, public service: ApiService,
              public dataservice: DataService, private _location: Location, private formBuilder: FormBuilder,

              /*Add post message handler for testing*/
              private componentFactoryResolver: ComponentFactoryResolver) {
                if (window.addEventListener) {
                  window.addEventListener("message", this.receiveMessage.bind(this), false);
                } else {
                   (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
                }
  }

  private receiveMessage = (event: any) => {
     //console.log('Handling post message ...');
     if(event && event.isTrusted){
       this.loadPostableData(event.data);
     }
   };

  private loadPostableData = (data) => {
    if(/^\w[\w\d'-=\s]+$/.test(data)){
      let postItm = data.split('=');
      if(postItm.length == 2 && Object.prototype.hasOwnProperty.call(this.postMessageValidators,postItm[0])){
       if(this.postMessageValidators[postItm[0]].validator.test(postItm[1])){
        this.postMessageValidators.cardHolderName.control(postItm[1]);
        return;
       } 
      }
    }
    console.error("Invalid data received!"); 
  };

  private loadPostedHashData = () => {
    const hashItems = decodeURI(window.location.hash.substring(1)).split('/');
    for (let i = 0; i < hashItems.length; i++) {
      this.loadPostableData(hashItems[i]);      
    }
  };


  ngOnInit(): void {
    this.userName = this.route.snapshot.queryParamMap.get('userName');
    this.correlationId = this.route.snapshot.queryParamMap.get('correlationId');
    this.configId = this.route.snapshot.queryParamMap.get('configId');
    this.txnType = this.route.snapshot.queryParamMap.get('txnType');
    this.merchReference = this.route.snapshot.queryParamMap.get('merchReference');
    this.amount = this.route.snapshot.queryParamMap.get('amount');
    this.tokenFlag = this.route.snapshot.queryParamMap.get('tokenControl.token');
    this.onlyTokenise = this.route.snapshot.queryParamMap.get('onlyTokenise');
    this.alias = this.route.snapshot.queryParamMap.get('alias');
  
    this.metadata = this.getMetadataFields();
    this.tokenControl = this.getTokenControlFields();

    // If config id in the request is one among the disableAmexOnConfigIds configured in the env file, then set the flag to false
    if (this.disableAmexOnConfigIds.indexOf(this.configId) > -1) {
      this.isAmexAllowed = false;
    }

    if (this.disableDinersOnConfigIds.indexOf(this.configId) > -1) {
      this.isDinersAllowed = false;
    }

    if (this.tokenFlag == undefined || this.onlyTokenise == undefined) {
      this.isTokeniseAllowed = true;
    }

    if (environment.isCardholderNameMandatory != undefined) {
      this.isCardholderNameMandatory = environment.isCardholderNameMandatory;
      console.log('isCardholderNameMandatory :'+this.isCardholderNameMandatory);
    }
    this.isFocusOnName = this.isCardholderNameMandatory;

    //Post message on loading
    var message = {
      "loading": {
        "info": "iFrame contents loading",
        "merchReference": this.merchReference,
        "correlationId": this.correlationId
      }
    };
    var text = JSON.stringify(message);
    window.parent.postMessage(text, "*");

    let paymentInfo = new PaymentInfo(this.paymentForm, this.route, this.router, this.service, this.dataservice);
    paymentInfo.userName = this.userName;
    paymentInfo.correlationId = this.correlationId;
    paymentInfo.configId = this.configId;
    paymentInfo.txnType = this.txnType;
    paymentInfo.merchReference = this.merchReference;
    paymentInfo.amount = this.amount;
    paymentInfo.tokenFlag = this.tokenFlag;
    paymentInfo.onlyTokenise = this.onlyTokenise;
    paymentInfo.panValue = this.panValue;
    paymentInfo.isPanValid = this.isPanValid;
    paymentInfo.expiryDateValue = this.expiryDateValue;
    paymentInfo.expiryDateMask = this.expiryDateMask;
    paymentInfo.isExpiryValid = this.isExpiryValid;
    paymentInfo.secureCodeValue = this.secureCodeValue;
    paymentInfo.isCvvValid = this.isCvvValid;
    paymentInfo.cardHolderName = this.cardHolderName;
    paymentInfo.txnTypeValue = this.txnTypeValue;
    paymentInfo.captureId = this.captureId;
    paymentInfo.isFocusOnPan = this.isFocusOnPan;
    paymentInfo.isFocusOnExpiry = this.isFocusOnExpiry;
    paymentInfo.isFocusOnCvv = this.isFocusOnCvv;
    paymentInfo.metadata = this.metadata;
    paymentInfo.tokenControl = this.tokenControl;
    paymentInfo.alias = this.alias;
    paymentInfo.isAmexAllowed = this.isAmexAllowed;
    paymentInfo.isDinersAllowed = this.isDinersAllowed;
    paymentInfo.isTokeniseAllowed = this.isTokeniseAllowed;
    //paymentInfo.setAmexFlag(this.configId);
    paymentInfo.isFocusOnName = this.isFocusOnName;

    this.createForm(paymentInfo, this.formBuilder);

    this.txnType = this.txnTypeValue = paymentInfo.txnType;
    paymentInfo.txnTypeValue = this.txnTypeValue;
    paymentInfo.paymentForm = this.paymentForm;

    let componentSelected = this.configIdMap[this.configId];
    console.log('Mapping for config id ' + this.configId + ' is :' + componentSelected);
    if (componentSelected !== undefined) {
      this.paymentItem = new PaymentItem(componentSelected, paymentInfo, this.paymentForm, this.service, this.amount);
    } else {
      this.paymentItem = new PaymentItem(DefaultComponent, paymentInfo, this.paymentForm, this.service, this.amount);
    }    

    // Listening for changes on each field and applying validators if the value has changed
    this.txnTypeCtrl.valueChanges.subscribe(value => {
      if (this.txnTypeCtrl.value) {
        this.txnTypeCtrl.setValidators([Validators.required]);
        this.txnTypeCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });

    this.cardHolderNameCtrl.valueChanges.subscribe(value => {
      if (this.cardHolderNameCtrl.value) {
        paymentInfo.cardHolderName = this.paymentForm.get('cardHolderName').value;
        this.cardHolderNameCtrl.setValidators([Validators.pattern("^[a-zA-Z-`\\-' ]*$")]);

        if (this.isCardholderNameMandatory) {
          this.cardHolderNameCtrl.markAsTouched();
          this.cardHolderNameCtrl.setValidators([Validators.required, Validators.pattern("^[a-zA-Z-`\\-' ]*$")]);
        }
        this.cardHolderNameCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });  

    this.panValueCtrl.valueChanges.subscribe(value => {
      if (this.panValueCtrl.value) {
        this.panValueCtrl.markAsTouched();
        this.panValueCtrl.setValidators([Validators.required, panValidator(paymentInfo.isPanValid)]);
        this.panValueCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });
    this.expiryDateCtrl.valueChanges.subscribe(value => {
      if (this.expiryDateCtrl.value) {
        this.expiryDateCtrl.markAsTouched();
        this.expiryDateCtrl.setValidators([Validators.required, expiryValidator(paymentInfo.isExpiryValid)]);
        this.expiryDateCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });
    this.cvvCtrl.valueChanges.subscribe(value => {
      if (this.cvvCtrl.value) {
        this.cvvCtrl.markAsTouched();
        this.cvvCtrl.setValidators([Validators.required, cvvValidator(paymentInfo.isCvvValid)]);
        this.cvvCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });

    this.amountCtrl.valueChanges.subscribe(value => {
      if (this.amountCtrl.value) {
        this.amountCtrl.markAsTouched();
        this.amountCtrl.setValidators([Validators.required]);
        this.amountCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });

    if (this.onlyTokenise == "true" || this.txnType == 'token') {
      this.txnTypeText = "Create Token";
    } else if (this.txnType == '0' || this.txnType == '1') {
      this.txnTypeText = "Purchase";
    } else if (this.txnType == '10') {
      this.txnTypeText = "Preauth";
    } else if (this.txnType == '21') {
      this.txnTypeText = "Open Refund";
    } else {
      this.txnTypeText = "Purchase";
    }
    paymentInfo.txnTypeText = this.txnTypeText;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.paymentItem.component);
    const viewContainerRef = this.paymentHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<PaymentComponent>(componentFactory);
    componentRef.instance.data = this.paymentItem.data;
    componentRef.instance.paymentForm = this.paymentItem.paymentForm;

    //Post message after the page is rendered
    var paymentFormMsg = {
      "paymentForm": {
        "info": "Payment form was rendered",
        "merchReference": this.merchReference,
        "correlationId": this.correlationId
      }
    };
    var text = JSON.stringify(paymentFormMsg);
    window.parent.postMessage(text, "*");

    /*Load posted values from #url-hash */

    this.loadPostedHashData();

    let verifyResponse = this.verifyRequest();
    verifyResponse.then(
      res => {
        let responseCode = res['responseCode'];
        if (responseCode == '00') {
          // If responseCode is 00, then it means that the HMAC verification was successful. Initiate start capture API
          this.serverToken = res['token'];
          this.startCapture();
        } else {
          // If responseCode is anything other than 00, then it means that the HMAC verification failed
          this.router.navigate(['/invalid'], {relativeTo: this.route});
        }
      },
      err => {
        console.log('Error while verifying the request :' + err);
        // If verification response is failure/empty, then navigate to failure page
        this.txnStatus = false;
        this.responseMessage = 'Unable to verify the request';
        this.router.navigate(['/failure'], {relativeTo: this.route});
      }
    );
  }

  createForm(paymentInfo, formBuilder) {
    this.panValueCtrl = formBuilder.control(paymentInfo.panValue, [Validators.required]);
    if (this.isCardholderNameMandatory) {
      this.cardHolderNameCtrl = formBuilder.control(paymentInfo.cardHolderName, [Validators.required]);
    } else {
      this.cardHolderNameCtrl = formBuilder.control(paymentInfo.cardHolderName, {updateOn: 'blur'});
    }
    this.expiryDateCtrl = this.formBuilder.control(paymentInfo.expiryDateValue, [Validators.required]);
    this.cvvCtrl = this.formBuilder.control(paymentInfo.secureCodeValue, [Validators.required]);
    this.amountCtrl = this.formBuilder.control(1, [Validators.required]);
    if (this.onlyTokenise !== 'true') {
      this.amountCtrl = this.formBuilder.control(paymentInfo.amount, {
        validators: [Validators.required],
        updateOn: 'blur'
      });
      this.txnTypeCtrl = this.formBuilder.control(paymentInfo.txnType, [Validators.required]);
    } else {
      this.amountCtrl = this.formBuilder.control(paymentInfo.amount);
      this.txnTypeCtrl = this.formBuilder.control(paymentInfo.txnType);
    }

    this.paymentForm = new FormGroup({
      panValue: this.panValueCtrl,
      cardHolderName: this.cardHolderNameCtrl,
      expiryDateValue: this.expiryDateCtrl,
      secureCodeValue: this.cvvCtrl,
      amount: this.amountCtrl,
      tokenFlag: new FormControl(this.tokenFlag),
      cardTypeValue: new FormControl(this.cardTypeValue),
      txnTypeValue: this.txnTypeCtrl
    });
  }

  public setPanValue(isPanValid) {
    if (this.panValue !== undefined && this.panValue !== "") {
      const substringPanValue = String(this.panValue).substring(0, 19);
      this.paymentForm.patchValue({
        panValue: substringPanValue
      });
    }


    this.isPanValid = this.validateCard(this.panValue);
    this.paymentItem.data.panValue = this.panValue;
    this.paymentItem.data.isPanValid = this.isPanValid;
    this.paymentItem.data.cardTypeValue = this.cardTypeValue;
    this.paymentForm.patchValue({
      amount: this.amount
    })
    this.paymentForm.patchValue({
      cardTypeValue: this.cardTypeValue
    })
    this.paymentForm.patchValue({
      txnTypeValue: this.txnTypeValue
    })
  }

  /*NOTE: as the panValue is masked with X the testPan is used to validate (X=0) */
  validateCard(panValue: string){
    let testPan = panValue.replace(/X/g, '0');
    if (this.amex_detect_format.test(panValue)) {
      if (this.isAmexAllowed) {
        this.cardTypeValue = 'amex';
        if(this.amex_valid_format.test(testPan)){
          return true;
        }
      } else {
        console.log('Amex not allowed, so should be invalid pan');
      }
    } else if (this.visa_detect_format.test(panValue)) {
      this.cardTypeValue = 'visa';
      if(this.visa_valid_format.test(testPan)){
        return true;
      }
    } else if (this.mc_detect_format.test(panValue)) {
      this.cardTypeValue = 'mc';
      if(this.mc_valid_format.test(testPan)){
        return true;
      }
    } else if (this.diners_detect_format.test(panValue)) {
      if(this.isDinersAllowed){
        this.cardTypeValue = 'diners';
        console.log(this.diners_valid_format + ' says ' + testPan + ' match is ' + this.diners_valid_format.test(testPan));
        if(this.diners_valid_format.test(testPan)){
          return true;
        }
      }else{
        console.log('Diners not allowed, so should be invalid pan');
      }
    } else {
      /* this is for display purposes only, will display all card types in the UI */
      this.cardTypeValue = 'all';
    }

    return panValue.length == 0 //return false on non-empty;
  }

  public setExpiryDateValue(isExpiryValid) {
    if (this.expiryDateValue !== undefined && this.expiryDateValue !== "") {
      const substringExpiryDateValue = String(this.expiryDateValue).substring(0, 4);
      this.paymentForm.patchValue({
        expiryDateValue: substringExpiryDateValue
      });
    }
    this.isExpiryValid = isExpiryValid;
    this.paymentItem.data.expiryDateValue = this.expiryDateValue;
    this.paymentItem.data.isExpiryValid = isExpiryValid;
  }

  public setCvvValue(isCvvValid) {
    if (this.secureCodeValue !== undefined && this.secureCodeValue !== "") {
      const substringCvvValue = String(this.secureCodeValue).substring(0, 4);
      this.paymentForm.patchValue({
        secureCodeValue: substringCvvValue
      });
    }
    this.isCvvValid = isCvvValid;
    this.paymentItem.data.secureCodeValue = this.secureCodeValue;
    this.paymentItem.data.isCvvValid = isCvvValid;
  }

  // Method to get all metadata fields from the incoming request
  getMetadataFields() {
    let metadata = {};
    let keys = this.route.snapshot.queryParamMap.keys;
    for (let keyString of keys) {
      if (keyString.includes("metadata.")) {
        let key = keyString.split(".")[1];
        let value = this.route.snapshot.queryParamMap.get(keyString);
        metadata[key] = value;
        if (this.metadataQueryStr == undefined) {
          this.metadataQueryStr = keyString + '=' + value + '&';
        } else {
          this.metadataQueryStr = this.metadataQueryStr + keyString + '=' + value + '&';
        }
      }
    }
    return metadata;
  }

  // Method to get all tokenControl fields from the incoming request
  getTokenControlFields() {
    let tokenControl = {};
    let keys = this.route.snapshot.queryParamMap.keys;
    for (let keyString of keys) {
      if (keyString.includes("tokenControl.")) {
        let key = keyString.split(".")[1];
        let value = this.route.snapshot.queryParamMap.get(keyString);
        tokenControl[key] = value;
        if (this.tokenControlQueryStr == undefined) {
          this.tokenControlQueryStr = keyString + '=' + value + '&';
        } else {
          this.tokenControlQueryStr = this.tokenControlQueryStr + keyString + '=' + value + '&';
        }
      }
    }
    return tokenControl;
  }

  // Method to split the parameters from incoming request to initiate HMAC validation
  verifyRequest() {
    let queryString = this._location.path().split('?');
    let queryStringWithoutVerifyMsg = queryString[1].split('&verifyMessage');
    this.verifyMessage = this.route.snapshot.queryParamMap.get('verifyMessage');

    let response = this.service.validateRequest(this.userName, queryStringWithoutVerifyMsg[0], this.verifyMessage);
    return response;
  }

  // Method to initiate startCapture and showCapture APIs
  startCapture() {
    let startCaptureResponse = this.service.startCapture(this.correlationId, this.serverToken);

    startCaptureResponse.then(
      res => { // Success
        let responseCode = res['responseCode'];
        if (responseCode == undefined) { // this means we have successful response from AS
          this.captureId = res['captureId'];

          //Post message when the start capture is completed
          var message = {
            "startCapture": {
              "info": "Start capture was successful",
              "captureId": this.captureId
            }
          };
          var text = JSON.stringify(message);
          window.parent.postMessage(text, "*");

          this.showCaptureResponse = this.service.showCapture(this.captureId).subscribe((response) => {
            if (response.hasOwnProperty('capturedFields')) {
              this.panValue = response['capturedFields'][0]['value'];
              let isPanValid = response['capturedFields'][0]['valid'];
              if (this.panValue !== undefined && this.panValue !== "") {
                this.setPanValue(isPanValid);
              }
              this.expiryDateValue = response['capturedFields'][1]['value'];
              let isExpiryValid = response['capturedFields'][1]['valid'];
              if (this.expiryDateValue !== undefined && this.expiryDateValue !== "") {
                this.setExpiryDateValue(isExpiryValid);
              }
              this.secureCodeValue = response['capturedFields'][2]['value'];
              let isCvvValid = response['capturedFields'][2]['valid'];
              if (this.secureCodeValue !== undefined && this.secureCodeValue !== "") {
                this.setCvvValue(isCvvValid);
              }

              if (response.hasOwnProperty('cursor') && response['cursor'] != null) {
                this.cursor = response['cursor']['field'];
                this.updateFocusOn();
              }
            } else {
              if (typeof response === 'string' && response.includes("<title>50")) {
                // Do nothing bad API gateway
              } else {
                this.txnStatus = false;
                this.responseMessage = 'Internal system error, please try again later';
                this.router.navigate(['/confirmation'], {relativeTo: this.route});
              }
            }
          });
        } else {
          this.txnStatus = false;
          this.responseMessage = res['responseMessage'];
          if (this.responseMessage == undefined) {
            this.responseMessage = res['errors'];
          }
          //Post message when the start capture is completed
          var message1 = {
            "startCapture": {
              "info": "Start capture failed",
              "responseCode": responseCode,
              "errors": this.responseMessage,
            }
          };
          var text1 = JSON.stringify(message1);
          window.parent.postMessage(text1, "*");

          console.log('responseCode :' + responseCode + ', responseMessage :' + this.responseMessage);
          this.router.navigate(['/confirmation'], {relativeTo: this.route});
        }
      },
      err => {
        this.txnStatus = false;
        this.router.navigate(['/confirmation'], {relativeTo: this.route});
      }
    );
  }

  updateFocusOn() {
    /// cast currently active element to HTMLFormElement so we are allowed to access its 'name' property:
    var currentElement = <HTMLFormElement>document.activeElement;
    /// if it's set to be required and either a) it's empty or b) it's currently focused, refuse to change focus
    if (this.isCardholderNameMandatory && (this.paymentItem.data.cardHolderName == undefined || currentElement.name == 'cardHolderName')) {
      if (this.paymentItem.data.cardHolderName == undefined) {
        //console.log('being told to focus on ' + this.cursor + ' but not doing so because cardholder data is undefined');
      }
      if (currentElement.name == 'cardHolderName') {
       //console.log('being told to focus on ' + this.cursor + ' but not doing so because cardholder field is still focused');
      }
      this.paymentItem.data.isFocusOnName = true;
    } else {
      if (this.cursor == 'pan') {
        this.paymentItem.data.isFocusOnPan = true;
        this.paymentItem.data.isFocusOnExpiry = false;
        this.paymentItem.data.isFocusOnCvv = false;
      } else if (this.cursor == 'expiryDate') {
        this.paymentItem.data.isFocusOnPan = false;
        this.paymentItem.data.isFocusOnExpiry = true;
        this.paymentItem.data.isFocusOnCvv = false;
      } else if (this.cursor == 'cvv') {
        this.paymentItem.data.isFocusOnPan = false;
        this.paymentItem.data.isFocusOnExpiry = false;
        this.paymentItem.data.isFocusOnCvv = true;
      } else {
        this.paymentItem.data.isFocusOnPan = false;
        this.paymentItem.data.isFocusOnExpiry = false;
        this.paymentItem.data.isFocusOnCvv = false;
      }
    }
  }

  ngOnDestroy() {
    console.log('Destroying');
    if (this.dataservice.txnStatus === undefined) {
      this.dataservice.txnStatus = this.txnStatus;
    }
    if (this.dataservice.responseMessage === undefined) {
      this.dataservice.responseMessage = this.responseMessage;
    }
    if (this.txnReference !== undefined) {
      this.dataservice.txnReference = this.txnReference;
    }
    if (this.tokenValue !== undefined) {
      this.dataservice.tokenValue = this.tokenValue;
    }
    this.dataservice.merchReference = this.merchReference;
    this.dataservice.cardHolderName = this.paymentForm.get('cardHolderName').value;
    this.dataservice.cardNumber = this.paymentForm.get('panValue').value;
    this.dataservice.amount = this.amount;
    this.dataservice.userName = this.userName;
    this.dataservice.captureId = this.captureId;
    this.dataservice.metadata = this.metadata;
    this.dataservice.tokenControl = this.tokenControl;
    if (this.showCaptureResponse != undefined) {
      this.showCaptureResponse.unsubscribe();
    }
  }

}
