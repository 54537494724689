import { Component, OnInit } from "@angular/core";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-failure",
  templateUrl: "./failure.component.html",
  styleUrls: ["../confirmation/confirmation.component.css"],
})
export class FailureComponent implements OnInit {
  txnStatus: boolean;
  responseMessage: string;

  constructor(public dataservice: DataService) {}

  ngOnInit() {
    console.log("In failure component");
    this.txnStatus = this.dataservice.txnStatus;
    this.responseMessage = this.dataservice.responseMessage;
    const failMessage = {
      onFail: {
        data: {
          errors: {
            message: this.responseMessage,
          },
        },
      }
    };
    console.log("failure.component.ts failMessage being fired correctly....", {
      failMessage,
    });
    const text = JSON.stringify(failMessage);
    window.parent.postMessage(text, "*");
  }
}
