import {Component, Input} from '@angular/core';
import {PaymentInterface} from "./payment-interface.component";
import {FormGroup} from "@angular/forms";
import {PaymentInfo} from "./payment-info";

@Component({
  templateUrl: './paymentwithrefund.component.html',
  styleUrls: ['./payment.component.css']
})
export class WithRefundComponent implements PaymentInterface {
  @Input() data: PaymentInfo;
  paymentForm: FormGroup;

}
