import {Component, Input} from '@angular/core';
import {PaymentInterface} from "./payment-interface.component";
import {PaymentInfo} from "./payment-info";
import {FormGroup} from "@angular/forms";

@Component({
  templateUrl: './defaultpayment.component.html',
  styleUrls: ['./payment.component.css']
})

export class DefaultComponent implements PaymentInterface {
  @Input() data: PaymentInfo;
  paymentForm: FormGroup;
}
