import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {PaymentComponent} from './payment/payment.component';
import {ApiService} from './services/api.service';
import {RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {DataService} from './services/data.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InvalidRequestComponent} from './invalid-request/invalid-request.component';
import {FailureComponent} from './failure/failure.component';
import {PaymentDirective} from "./payment/payment.directive";
import {DefaultComponent} from "./payment/default.component";
import {WithRefundComponent} from "./payment/with-refund.component";
import {FocusDirective} from "./payment/focus.directive";
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    ConfirmationComponent,
    PageNotFoundComponent,
    InvalidRequestComponent,
    FailureComponent,
    PaymentDirective,
    DefaultComponent,
    WithRefundComponent,
    FocusDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
        {
          path: 'payment',
          component: PaymentComponent
        },
        {
          path: 'confirmation',
          component: ConfirmationComponent
        },
        {
          path: 'invalid',
          component: InvalidRequestComponent
        },
        {
          path: 'failure',
          component: FailureComponent
        },
        {
          path: '**',
          component: PageNotFoundComponent
        }
      ]
    ),
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule
  ],
  exports: [RouterModule],
  providers: [
    ApiService,
    DataService
  ],
  bootstrap: [AppComponent],
  entryComponents: [DefaultComponent, WithRefundComponent]
})
export class AppModule {
}
