export class DataService {
  public txnStatus: boolean;
  public responseMessage: string;
  public tokenValue: string;
  public merchReference: string;
  public cardHolderName: string;
  public cardNumber: string;
  public amount;
  public userName: string;
  public captureId: string;
  public txnReference: string;
  public metadata: Object;
  public tokenControl: Object;
  public alias: string;
}

