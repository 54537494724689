import {AbstractControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import {DataService} from "../services/data.service";
import {environment} from "../../environments/environment";

export class PaymentInfo {
  panValue;
  isPanValid: boolean = true;
  expiryDateValue;
  expiryDateMask: (string | RegExp)[];
  isExpiryValid: boolean = true;
  secureCodeValue;
  isCvvValid: boolean = true;
  responseMessage;
  txnStatus;
  tokenValue;
  correlationId: string;
  configId: string;
  userName: string;
  txnType: string;
  merchReference: string;
  txnReference: string;
  amount;
  verifyMessage: string;
  cardHolderName;
  tokenFlag: string;
  captureId: string;
  txnTypeText: string;
  onlyTokenise: string;
  cursor: string;
  pan: AbstractControl;
  expiry: AbstractControl;
  cvv: AbstractControl;
  amt: AbstractControl;
  cardTypeValue = 'all';
  txnTypeValue;
  isFocusOnName: boolean = false;
  isFocusOnPan: boolean = false;
  isFocusOnExpiry: boolean = false;
  isFocusOnCvv: boolean = false;
  metadata = {};
  tokenControl = {};
  alias: string;
  disableAmexOnConfigIds: string[];
  isAmexAllowed: boolean = true;
  isDinersAllowed: boolean = true;
  isTokeniseAllowed: boolean = false;
  /// more fields added for IPSI-2063
  additionalData: {};
  settlementDate: string;
  pspSelected: string;
  txnId: string;
  // Disable button for double clicks IPSI-2323
  disableSubmitButton: boolean = false;

  constructor(public paymentForm: FormGroup, private route: ActivatedRoute,
              private router: Router, public service: ApiService, public dataservice: DataService) {
    dataservice.amount = this.amount;
  }

  setTxnType(txnTypeValue) {
    console.log('Updating txntype to ' + txnTypeValue.target.value);
    this.txnTypeValue = txnTypeValue.target.value;
    if (this.onlyTokenise == "true" || this.txnTypeValue == 'token') {
      this.txnTypeText = "Create Token";
    } else if (this.txnTypeValue == '0' || this.txnTypeValue == '1') {
      this.txnTypeText = "Purchase";
      this.onlyTokenise = "false";
    } else if (this.txnTypeValue == '10') {
      this.txnTypeText = "Preauth";
      this.onlyTokenise = "false";
    } else if (this.txnTypeValue == '21') {
      this.txnTypeText = "Open Refund";
      this.onlyTokenise = "false";
    } else {
      this.txnTypeText = "Purchase";
      this.onlyTokenise = "false";
    }
  }

  public setPanValue(isPanValid) {
    if (this.panValue !== undefined && this.panValue !== "") {
      const substringPanValue = String(this.panValue).substring(0, 19);
      this.paymentForm.patchValue({
        panValue: substringPanValue
      });
    }

    if (this.panValue.startsWith('34') || this.panValue.startsWith('37')) {
      console.log('this.isAmexAllowed :' + this.isAmexAllowed);
      if (this.isAmexAllowed) {
        this.cardTypeValue = 'amex';
      } else {
        this.isPanValid = false;
      }
    } else if (this.panValue.startsWith('4')) {
      this.cardTypeValue = 'visa';
    } else if (this.panValue.startsWith('5')) {
      this.cardTypeValue = 'mc';
    } else {
      this.cardTypeValue = 'all';
    }
    this.isPanValid = isPanValid;
  }

  public setExpiryDateValue(isExpiryValid) {
    if (this.expiryDateValue !== undefined && this.expiryDateValue !== "") {
      const substringExpiryDateValue = String(this.expiryDateValue).substring(0, 4);
      this.paymentForm.patchValue({
        expiryDateValue: substringExpiryDateValue
      });
      this.isExpiryValid = isExpiryValid;
    }
  }

  public setCvvValue(isCvvValid) {
    if (this.secureCodeValue !== undefined && this.secureCodeValue !== "") {
      const substringCvvValue = String(this.secureCodeValue).substring(0, 4);
      this.paymentForm.patchValue({
        secureCodeValue: substringCvvValue
      });
      this.isCvvValid = isCvvValid;
    }
  }

  // Method to initiate updateCapture API
  updateData(e, updateDataField) {
    e.preventDefault();
    console.log('Updating field ' + updateDataField);
    let updateCaptureResponse = this.service.updateCapture(updateDataField);
    updateCaptureResponse.then(
      res => { // Success
        let responseCode = res['responseCode'];
        if (responseCode == undefined) { // this means we have successful response from AS
          this.captureId = res['captureId'];
          if (res.hasOwnProperty('capturedFields')) {
            switch (updateDataField) {
              case 'pan':
                this.panValue = "";
                this.paymentForm.patchValue({
                  panValue: ""
                });
                break;
              case 'expiryDate':
                this.expiryDateValue = "";
                this.paymentForm.patchValue({
                  expiryDateValue: ""
                });
                break;
              case 'cvv':
                this.secureCodeValue = "";
                this.paymentForm.patchValue({
                  secureCodeValue: ""
                });
                break;
              default:
                break;
            }

            let isPanValid = res['capturedFields'][0]['valid'];
            this.setPanValue(isPanValid);
            let isExpiryValid = res['capturedFields'][1]['valid'];
            this.setExpiryDateValue(isExpiryValid);
            let isCvvValid = res['capturedFields'][2]['valid'];
            this.setCvvValue(isCvvValid);

            if (res.hasOwnProperty('cursor')) {
              this.cursor = res['cursor']['field'];
              this.updateFocusOn();
            }
          } else {
            this.txnStatus = false;
            this.responseMessage = 'Internal system error, please try again later';
            this.router.navigate(['/confirmation'], {relativeTo: this.route});
          }
        } else {
          this.txnStatus = false;
          this.responseMessage = res['responseMessage'];
          if (this.responseMessage == undefined) {
            this.responseMessage = res['errors'];
          }
          console.log('responseCode :' + responseCode + ', responseMessage :' + this.responseMessage);
          this.router.navigate(['/confirmation'], {relativeTo: this.route});
        }
      },
      err => {
        this.txnStatus = false;
        this.router.navigate(['/confirmation'], {relativeTo: this.route});
      }
    );
  }

  // Method to identify the transaction to be submitted and call the sub-methods
  submitTransaction() {
    this.disableSubmitButton = true;
    if (this.onlyTokenise == "true") {
      this.tokenisation();
    } else {
      if (this.tokenFlag == "true") {
        this.processTransaction(true);
      } else {
        this.processTransaction(false);
      }
    }
  }

  // Method to initiate processTransaction or Purchase API. The tokenFlag determines whether the API is just for Purchase or for Purchase and Tokenise
  processTransaction(tokenFlag) {
    this.tokenControl['token'] = tokenFlag;
    //Post message when the payment is submitted
    var message = {
      "submitPaymentForm": {
        "info": "The payment form was submited",
        "merchReference": this.merchReference,
        "captureId": this.captureId
      }
    };
    var text = JSON.stringify(message);
    window.parent.postMessage(text, "*");

    // Formatting amount : If amount has decimal point, remove the decimal and convert to numeric value.
    // If not, multiply by 100 to convert to cents
    let amtStr = String(parseFloat(this.amount).toFixed(2));
    let amountInt;
    if (amtStr.indexOf('.') > 0) {
      amtStr = String(parseFloat(this.amount).toFixed(2));
      let newAmt = amtStr.replace('.', '');
      amountInt = parseInt(newAmt);
    } else {
      amountInt = Math.trunc(this.amount * 100);
    }
    let amountToDisplay = (amountInt / 100).toFixed(2);
    console.log('Initiating Purchase for amount ' + amountInt);
    this.amount = amountToDisplay;

    let txnResponse;
    if (this.txnTypeValue == '21') {
      console.log('Initiating refund');
      txnResponse = this.service.processRefund(this, amountInt);
    } else {
      console.log('Initiating transaction with type ' + this.txnTypeValue);
      txnResponse = this.service.processTransaction(this, amountInt);
    }

    txnResponse.then(
      res => {
        let responseCode = res['responseCode'];
        let maskedPAN = res['maskedPAN'];
        let cardSchema = res['cardSchema'];
        let cardExpiryDate = res['cardExpiryDate'];
        this.txnReference = res['txnReference'];
        let cardToken = res['cardToken'];
        let message = res['message'];
        let errors = res['errors'];
        let transactionDate = res['transactionDate'];
        let txnAmount = res['amount'];
        let metadata = res['metadata'];
        /// IPSI-2063 add more fields
        let additionalData = res['additionalData'];
        let settlementDate = res['settlementDate'];
        let pspSelected  = res['pspSelected'];
        let txnId  = res['txnId'];

        interface MessageStr {
          paymentOutcome: {
            [key: string]: any;
          };
        }

        //Post message on payment outcome
        var messageStr:MessageStr = {
          "paymentOutcome": {
            "info": "Payment outcome is the following",
            "merchReference": this.merchReference,
            "amount": txnAmount,
            "maskedPan": maskedPAN,
            "cardSchema": cardSchema,
            "cardHolderName": this.cardHolderName,
            "cardExpiryDate": cardExpiryDate,
            "txnReference": this.txnReference,
            "cardToken": cardToken,
            "responseCode": responseCode,
            "message": message,
            "errors": errors,
            "transactionDate": transactionDate,
            "metadata": metadata,
            "alias": this.alias,
          }
        };

        /// It appears from the ticket that they may or may not be returned, so, conditionals:
        if(additionalData != undefined){
          messageStr.paymentOutcome.additionalData = additionalData;
        }
        if(settlementDate != undefined){
          messageStr.paymentOutcome.settlementDate = settlementDate;
        }
        if(pspSelected != undefined){
          messageStr.paymentOutcome.pspSelected = pspSelected;
        }
        if(txnId != undefined){
          messageStr.paymentOutcome.txnId = txnId;
        }

        var text = JSON.stringify(messageStr);
        window.parent.postMessage(text, "*");

        if (PaymentInfo.isSuccess(responseCode)) { // Success
          this.txnStatus = true;
          this.responseMessage = message;
          this.tokenValue = res['cardToken'];
          this.setDataService();
          console.log('responseCode :' + responseCode + ', responseMessage :' + this.responseMessage);
          this.router.navigate(['/confirmation'], {
            relativeTo: this.route
          });
        } else { // Failure
          this.txnStatus = false;
          this.responseMessage = (this.responseMessage == undefined) ? res['errors'] : res['responseMessage'];
          if (this.responseMessage == undefined) {
            this.responseMessage = "Internal system error, please try again later";
          }
          this.setDataService();
          console.log('responseCode :' + responseCode + ', responseMessage :' + this.responseMessage);
          this.router.navigate(['/confirmation'], {relativeTo: this.route});
        }

        // Update disableSubmitButton to false after transaction processed
        this.disableSubmitButton = false;
      },
      err => {
        this.txnStatus = false;
        this.setDataService();
        this.router.navigate(['/confirmation'], {relativeTo: this.route});
      }
    );
  }

  // Method to initiate Tokenisation API.
  tokenisation() {
    console.log('Initiating tokenisation');
    let tokenisationResponse = this.service.tokenise(this.metadata, this.tokenControl, this.alias, this.cardHolderName);

    tokenisationResponse.then(
      res => {
        this.tokenValue = res['cardToken'];
        let maskedPAN = res['maskedPAN'];
        let cardExpiryDate = res['cardExpiryDate'];
        let lastAccessed = res['lastAccessed'];
        let lastUpdated = res['lastUpdated'];
        let cardSchema = res['cardSchema'];
        let messageValue = res['message'];
        let errors = res['errors'];
        let cardCategory = res['cardCategory'];
        let channel = res['channel'];
        let alias = res['alias'];
        let cardIssuer = res['cardIssuer'];
        let cardIssuerCountry = res['cardIssuerCountry'];
        let creatorUserName = res['creatorUserName'];
        let creatorHierarchy = res['creatorHierarchy'];
        let customerId = res['customerId'];
        let metadata = res['metadata'];
        let tokenSilo = res['tokenSilo'];
        let cardSubcategory = res['cardSubcategory'];

        //Post message on tokenisation
        var message = {
          "paymentOutcome": {
            "info": "Payment outcome for the transaction.",
            "body": `Successfully tokenised card details ${this.tokenValue}`,
            "success": "true",
            "header": "Create Token",
            "maskedPAN": maskedPAN,
            "cardHolderName": this.cardHolderName,
            "cardExpiryDate": cardExpiryDate,
            "cardToken": this.tokenValue,
            "tokenSilo": tokenSilo,
            "lastAccessed": lastAccessed,
            "lastUpdated": lastUpdated,
            "cardSchema": cardSchema,
            "message": messageValue,
            "errors": errors,
            "cardCategory": cardCategory,
            "channel": channel,
            "alias": alias,
            "cardSubcategory": cardSubcategory,
            "cardIssuer": cardIssuer,
            "cardIssuerCountry": cardIssuerCountry,
            "creatorUserName": creatorUserName,
            "creatorHierarchy": creatorHierarchy,
            "customerId": customerId,
            "metadata": metadata
          }
        };
        var text = JSON.stringify(message);
        window.parent.postMessage(text, "*");

        if (this.tokenValue != undefined) { // Success
          this.txnStatus = true;
          this.setDataService();
          this.router.navigate(['/confirmation'], {relativeTo: this.route});
        } else { // Failure
          this.txnStatus = false;
          this.responseMessage = (this.responseMessage == undefined) ? res['errors'] : res['responseMessage'];
          if (this.responseMessage == undefined) {
            this.responseMessage = "Internal system error, please try again later";
          }
          this.setDataService();
          this.router.navigate(['/confirmation'], {relativeTo: this.route});
        }
        // Update disableSubmitButton to false after tokenisation processed
        this.disableSubmitButton = false;
      },
      err => {
        this.txnStatus = false;
        this.setDataService();
        this.router.navigate(['/confirmation'], {relativeTo: this.route});
      }
    );
  }

  updateTokenFlag() {
    if (this.tokenFlag == undefined || this.tokenFlag == 'false') {
      this.tokenFlag = 'true';
    } else {
      this.tokenFlag = 'false';
    }
  }

  updateFocusOn() {
    if (this.cursor == 'pan') {
      this.isFocusOnPan = true;
      this.isFocusOnExpiry = false;
      this.isFocusOnCvv = false;
    } else if (this.cursor == 'expiryDate') {
      this.isFocusOnPan = false;
      this.isFocusOnExpiry = true;
      this.isFocusOnCvv = false;
    } else if (this.cursor == 'cvv') {
      this.isFocusOnPan = false;
      this.isFocusOnExpiry = false;
      this.isFocusOnCvv = true;
    } else {
      this.isFocusOnPan = false;
      this.isFocusOnExpiry = false;
      this.isFocusOnCvv = false;
    }
  }

  onReset() {
    window.location.reload()
  }

  public setDataService() {
    this.dataservice.txnStatus = this.txnStatus;
    this.dataservice.responseMessage = this.responseMessage;
    this.dataservice.txnReference = this.txnReference;
    this.dataservice.tokenValue = this.tokenValue;
    this.dataservice.merchReference = this.merchReference;
    this.dataservice.cardHolderName = this.cardHolderName;
    this.dataservice.cardNumber = this.panValue;
    this.dataservice.amount = this.amount;
    this.dataservice.userName = this.userName;
    this.dataservice.captureId = this.captureId;
    this.dataservice.metadata = this.metadata;
    this.dataservice.tokenControl = this.tokenControl;
    this.dataservice.alias = this.alias;
  }

  public static isSuccess(responseCode: string) {
    let successCodes = ['00', '08', '10', '11', '16', '77'];
    if (successCodes.indexOf(responseCode) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // ** Commenting this as it is stupid, there is already logic to set the isAllowed value based on the configIds TODO: remove this and other duplicate logic in here
  //
  // If config id in the request is one among the disableAmexOnConfigIds configured in the env file, then set the flag to false
  // public setAmexFlag(configId) {
  //   this.disableAmexOnConfigIds = environment.disableAmexOnConfigIds;
  //   this.configId = configId;
  //   if (this.disableAmexOnConfigIds.indexOf(this.configId) > -1) {
  //     this.isAmexAllowed = false;
  //   }
  // }
}
