import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {interval, Observable} from 'rxjs';
import {catchError, startWith, switchMap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {PaymentInfo} from "../payment/payment-info";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  iFrameExpressServerApiUrl = environment.iFrameExpressServerApiUrl;
  pollingData: any;
  pollingTime = environment.pollingTime;
  captureId;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient) {
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'/*,
      'Access-Control-Allow-Origin': '*'*/
    })
  }

  // Initiating validate API with EnterpriseSecure to validate the HMAC passed by the merchant
  validateRequest(userName: string, queryString: string, verifyMessage: string) {
    console.log("Calling api to validate and fetch the data");
    let requestBody = {
      "userName": userName,
      "data": queryString,
      "verifyMessage": verifyMessage
    };

    let response = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/validate', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return response;
  }

  // Initiating start capture API with AgentSecure to start capturing the data
  startCapture(correlationId: string, serverToken: string): Promise<unknown> {
    console.log("Calling agent secure start capture api");
    let requestBody = {
      "correlationId": correlationId
    };

    // Adding Authorization in header for all subsequent requests
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + serverToken
      })
    }

    let startCaptureResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/startCapture', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return startCaptureResponse;
  }

  // Initiating show capture API with AgentSecure to start pulling the entered data
  showCapture(captureId: string): Observable<any> {
    console.log("Calling agent secure show capture api");
    this.captureId = captureId;
    let requestBody = {
      "captureId": this.captureId
    };

    this.pollingData = interval(this.pollingTime)
      .pipe(
        startWith(0),
        switchMap(() => {
          return this.httpClient
            .post<any>(this.iFrameExpressServerApiUrl + '/showCapture', JSON.stringify(requestBody), this.httpOptions)
            .pipe(catchError((err: HttpErrorResponse) => {
              console.log("showCapture() HTTP Error Response: ", err)
              return null;
            })
          )
        })
      );

    return this.pollingData;
  }

  // Initiating update capture API with AgentSecure to update the data
  updateCapture(updateDataField: any) {
    let updateFields = [updateDataField];

    console.log(`Calling agent secure update capture api on ${updateDataField}`);
    let requestBody = {
      "captureId": this.captureId,
      "field": updateFields
    };

    let updateCaptureResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/updateCapture', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return updateCaptureResponse;
  }

  // Initiating purchase/purchase with token API with AgentSecure with the entered data
  processTransaction(paymentInfo: PaymentInfo, amount: number) {
    console.log("Calling agent secure process transaction api for txnType :" + paymentInfo.txnTypeValue);
    let requestBody = {
      "amount": amount,
      "captureId": this.captureId,
      "merchReference": paymentInfo.merchReference,
      "cardHolderName": paymentInfo.cardHolderName,
      "txnType": Number(paymentInfo.txnTypeValue),
      "metadata": paymentInfo.metadata,
      "tokenControl": paymentInfo.tokenControl
    };

    let processTransactionResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/processTransaction', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return processTransactionResponse;
  }

  // Initiating tokenisation API with AgentSecure to get the token generated out of the entered data
  tokenise(metadata: Object, tokenControl: Object, alias: string, cardHolderName: string) {
    console.log("Calling agent secure tokeniseCapture api");
    let tokenExpiryDate = null;
    let tokenFormat = null;
    let tokenLifeTime = null;
    let tokenUsageLimit = null;
    if (null !== tokenControl['tokenExpiryDate']) {
      tokenExpiryDate = tokenControl['tokenExpiryDate'];
    }
    if (null !== tokenControl['tokenFormat']) {
      tokenFormat = tokenControl['tokenFormat'];
    }
    if (null !== tokenControl['tokenLifeTime']) {
      tokenLifeTime = tokenControl['tokenLifeTime'];
    }
    if (null !== tokenControl['tokenUsageLimit']) {
      tokenUsageLimit = tokenControl['tokenUsageLimit'];
    }

    let requestBody = {
      "captureId": this.captureId,
      "metadata": metadata,
      "tokenExpiryDate": tokenExpiryDate,
      "tokenFormat": tokenFormat,
      "tokenLifeTime": tokenLifeTime,
      "tokenUsageLimit": tokenUsageLimit,
      "alias": alias,
      "cardHolderName": cardHolderName
    };

    let tokenisationResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/tokeniseCapture', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return tokenisationResponse;
  }

  // Initiating generate API with EnterpriseSecure to generate HMAC for new payment request
  getHMAC(userName: string, data: string): Promise<any> {
    console.log("Calling server to initiate ES generate api");
    let requestBody = {
      "userName": userName,
      "data": data
    };

    let generateHmacResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/generate', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return generateHmacResponse;
  }

  // Initiating purchase/purchase with token API with AgentSecure with the entered data
  processRefund(paymentInfo: PaymentInfo, amount: number) {
    console.log("Calling agent secure process refund api with txnType :" + paymentInfo.txnTypeValue);
    let requestBody = {
      "amount": amount,
      "captureId": this.captureId,
      "merchReference": paymentInfo.merchReference,
      "cardHolderName": paymentInfo.cardHolderName,
      "txnType": Number(paymentInfo.txnTypeValue),
      "metadata": paymentInfo.metadata
    };

    let processRefundResponse = this.httpClient
      .post<any>(this.iFrameExpressServerApiUrl + '/processRefund', JSON.stringify(requestBody), this.httpOptions)
      .toPromise();
    return processRefundResponse;
  }

}
